<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con" style="padding-top:10px">
        <salesSee pageName="crm" />
      </div>
    </div>
  </div>
</template>
<script>
import salesSee from '@/views/work-order/sales-see/comp'
export default {
  components: {
    salesSee
  }
}
</script>
<style>

</style>
